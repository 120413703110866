@import '../../../../scss/theme-bootstrap';

.elc_notice_popup_v1_modal {
  &:target {
    &:before {
      display: none;
    }
    .modal-dialog {
      top: -100%;
    }
  }
  &:before {
    content: '';
    display: block;
    background: $color-grey-transparent;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    bottom: 0;
    z-index: 1000;
  }
  .modal-dialog {
    background: $color-shimmer-grey-light;
    border: $color-primary-700 solid 1px;
    border-radius: 5px;
    margin-#{$ldirection}: -180px;
    position: fixed;
    #{$ldirection}: 50%;
    z-index: 1100;
    width: 360px;
    top: 10%;
    @include breakpoint($medium-up) {
      width: 650px;
      margin-#{$ldirection}: -320px;
    }
    @include breakpoint($extra-large-up) {
      top: 25%;
      width: 650px;
      margin-#{$ldirection}: -320px;
    }
    overflow-y: initial;
  }
  .btn {
    &.btn-big {
      font-size: 18px;
      padding: 15px 20px;
      min-width: 100px;
    }
  }
  .btn-close {
    color: $color-primary-500;
    font-size: 30px;
    text-decoration: none;
    position: absolute;
    #{$rdirection}: 7px;
    top: 5px;
    &:hover {
      color: $color-primary-900;
    }
  }
  .modal-body {
    padding: 20px;
    font-size: 16px;
    height: 550px;
    overflow-y: auto;
  }
  .modal-header {
    padding: 10px 20px;
    border-bottom: $color-primary-200 solid 1px;
  }
  .modal-footer {
    padding: 10px 20px;
    border-top: $color-primary-200 solid 1px;
    text-align: #{$rdirection};
  }
}
